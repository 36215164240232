import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    Button,
    Typography,
    Autocomplete
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Swal from 'sweetalert2';
import axios from 'axios';
import { GetallData, AddInventoryToOffline } from '../../Api_url';
const AddInventory = ({ openAddDialog, setOpenAddDialog }) => {
    const [rows, setRows] = useState([{
        product_name: '',
        hsn_code: '',
        item_code: '',
        weight: '',
        product_description: '',
        composition: '',
        measurement_type: '',
        category: '',
        gst: '',
        quantity: '',
        variant_name: '',
        variant_weight: '',
        manufacturing_date: '',
        expiry_date: ''
    }]);

    const [categories, setCategories] = useState([]); 
    const [measurementTypes, setMeasurementTypes] = useState([]); 

    useEffect(() => {
        async function fetchCategoriesAndMeasurements() {
            try {
                const categoryResponse = await axios.get(GetallData, {
                    params: { filter_type: 'category' }
                });

                const measurementResponse = await axios.get(GetallData, {
                    params: { filter_type: 'measurements' }
                });

                // Assuming response structure is { status: 'success', data: [{ category_id, name }, ...] }
                if (categoryResponse?.data?.status === 'success') {
                    setCategories(categoryResponse?.data?.data); // Set categories
                } else {
                    Swal.fire('Error', 'Failed to load categories', 'error');
                }

                if (measurementResponse.data.status === 'success') {
                    setMeasurementTypes(measurementResponse?.data?.data); // Set measurement types
                } else {
                    Swal.fire('Error', 'Failed to load measurement types', 'error');
                }
            } catch (error) {
                Swal.fire('Error', 'Failed to fetch data', 'error');
            }
        }

        fetchCategoriesAndMeasurements();
    }, []);

    const handleAddRow = () => {
        setRows([...rows, {
            product_name: '',
            hsn_code: '',
            item_code: '',
            weight: '',
            product_description: '',
            composition: '',
            measurement_type: '',
            category: '',
            gst: '',
            quantity: '',
            variant_name: '',
            variant_weight: '',
            manufacturing_date: '',
            expiry_date: ''
        }]);
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((_, i) => i !== index);
        setRows(newRows);
    };

    const handleSubmit = async () => {

        for (let row of rows) {
            if (
                !row.product_name || !row.hsn_code || !row.item_code || !row.weight ||
                !row.product_description || !row.composition || !row.measurement_type ||
                !row.category || !row.gst || !row.quantity || !row.variant_name ||
                !row.variant_weight || !row.manufacturing_date || !row.expiry_date
            ) {
                Swal.fire({
                    icon: 'warning',
                    title: 'All Fields are required',
                    text: 'Please fill all the fields.',
                    confirmButtonText: 'OK',
                    didOpen: () => {
                        const swalElement = document.querySelector('.swal2-container');
                        if (swalElement) {
                            swalElement.style.zIndex = 1500;
                        }
                    }
                });
                return;
            }
        }


        const token = localStorage.getItem('access_token');

        // Prepare data for the API
        const inventoryData = rows?.map(row => ({
            product_name: row?.product_name,
            hsn_code: row?.hsn_code,
            item_code: row?.item_code,
            weight: parseFloat(row?.weight),
            product_description: row?.product_description,
            composition: row?.composition,
            measurement_type: parseInt(row?.measurement_type), // Ensure measurement_type is an integer
            category: parseInt(row?.category), // Ensure category is an integer
            gst: parseFloat(row?.gst),
            quantity: parseInt(row?.quantity),
            variants: [
                {
                    variant_name: row?.variant_name,
                    weight: parseFloat(row?.variant_weight),
                    manufacturing_date: row?.manufacturing_date,
                    expiry_date: row?.expiry_date
                }
            ]
        }));

        try {
            const response = await axios.post(
                AddInventoryToOffline,
                { products: inventoryData },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response?.status === 201) {
                Swal.fire('Success', 'Inventory added successfully!', 'success');
                setOpenAddDialog(false); // Close the dialog after successful submission
            } else {
                Swal.fire('Error', response?.data?.message || 'Something went wrong', 'error');
                setOpenAddDialog(false);
            }
        } catch (error) {
            Swal.fire('Error', error?.response?.data?.message || 'Something went wrong', 'error');
            setOpenAddDialog(false);
        }
    };

    return (
        <Dialog
            open={openAddDialog}
            onClose={() => setOpenAddDialog(false)}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '95%',
                    height: '100%',
                    maxHeight: '85%',
                    margin: 'auto',
                },
            }}
        >
            <DialogTitle sx={{ marginBottom: 2 }} className='bg-[#00B251] text-white'>
                Add Inventory
            </DialogTitle>
            <DialogContent className='flex' sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {rows.map((row, index) => (
                    <div key={index} className="w-full flex flex-wrap gap-4 relative">
                        <div className="w-full p-4 bg-gray-50 rounded-lg shadow-md relative">

                            {rows.length > 1 && (
                                <IconButton
                                    onClick={() => handleDeleteRow(index)}
                                    color="error"
                                    className="absolute"
                                    sx={{ top: 10, right: 10 }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}

                            {/* Product Details Input Fields */}
                            <div className="flex justify-between items-center pb-2 border-b mb-4">
                                <Typography variant="h6" className="text-xl font-medium">
                                    {`Product - ${index + 1}`}
                                </Typography>
                            </div>

                            {/* Form Fields */}
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
                                {/* Category Autocomplete */}
                                <Autocomplete
                                    fullWidth
                                    options={categories}
                                    getOptionLabel={(option) => option?.name}
                                    value={categories.find(cat => cat?.category_id === row?.category) || null}
                                    onChange={(e, value) => {
                                        const updatedRows = [...rows];
                                        updatedRows[index].category = value ? value?.category_id : '';
                                        setRows(updatedRows);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Category" margin="dense" />
                                    )}
                                />

                                {/* Measurement Type Autocomplete */}
                                <Autocomplete
                                    fullWidth
                                    options={measurementTypes}
                                    getOptionLabel={(option) => option?.description}
                                    value={measurementTypes.find(m => m?.measurement_id === row?.measurement_type) || null}
                                    onChange={(e, value) => {
                                        const updatedRows = [...rows];
                                        updatedRows[index].measurement_type = value ? value?.measurement_id : '';
                                        setRows(updatedRows);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Measurement Type" margin="dense" />
                                    )}
                                />

                                {/* Existing Fields */}
                                <TextField
                                    label="Product Name"
                                    fullWidth
                                    margin="dense"
                                    value={row?.product_name}
                                    onChange={(e) => {
                                        const updatedRows = [...rows];
                                        updatedRows[index].product_name = e?.target?.value;
                                        setRows(updatedRows);
                                    }}
                                />

                                <TextField
                                    label="HSN Code"
                                    fullWidth
                                    margin="dense"
                                    value={row?.hsn_code}
                                    onChange={(e) => {
                                        const updatedRows = [...rows];
                                        updatedRows[index].hsn_code = e?.target?.value;
                                        setRows(updatedRows);
                                    }}
                                />

                                <TextField
                                    label="Item Code"
                                    fullWidth
                                    margin="dense"
                                    value={row?.item_code}
                                    onChange={(e) => {
                                        const updatedRows = [...rows];
                                        updatedRows[index].item_code = e?.target?.value;
                                        setRows(updatedRows);
                                    }}
                                />

                                <TextField
                                    label="Weight"
                                    fullWidth
                                    margin="dense"
                                    value={row?.weight}
                                    onChange={(e) => {
                                        const updatedRows = [...rows];
                                        updatedRows[index].weight = e?.target?.value;
                                        setRows(updatedRows);
                                    }}
                                />

                                <TextField
                                    label="Product Description"
                                    fullWidth
                                    margin="dense"
                                    value={row?.product_description}
                                    onChange={(e) => {
                                        const updatedRows = [...rows];
                                        updatedRows[index].product_description = e?.target?.value;
                                        setRows(updatedRows);
                                    }}
                                />

                                <TextField
                                    label="Composition"
                                    fullWidth
                                    margin="dense"
                                    value={row?.composition}
                                    onChange={(e) => {
                                        const updatedRows = [...rows];
                                        updatedRows[index].composition = e?.target?.value;
                                        setRows(updatedRows);
                                    }}
                                />

                                <TextField
                                    label="GST"
                                    fullWidth
                                    margin="dense"

                                    value={row?.gst}
                                    onChange={(e) => {
                                        const updatedRows = [...rows];
                                        updatedRows[index].gst = e?.target?.value;
                                        setRows(updatedRows);
                                    }}
                                />

                                <TextField
                                    label="Quantity"
                                    fullWidth
                                    margin="dense"
                                    type="number"
                                    value={row?.quantity}
                                    onChange={(e) => {
                                        const updatedRows = [...rows];
                                        updatedRows[index].quantity = e?.target?.value;
                                        setRows(updatedRows);
                                    }}
                                />

                                {/* Variant Details */}
                                <TextField
                                    label="Variant Name"
                                    fullWidth
                                    margin="dense"
                                    value={row?.variant_name}
                                    onChange={(e) => {
                                        const updatedRows = [...rows];
                                        updatedRows[index].variant_name = e?.target?.value;
                                        setRows(updatedRows);
                                    }}
                                />

                                <TextField
                                    label="Variant Weight"
                                    fullWidth
                                    margin="dense"
                                    type="number"
                                    value={row?.variant_weight}
                                    onChange={(e) => {
                                        const updatedRows = [...rows];
                                        updatedRows[index].variant_weight = e?.target?.value;
                                        setRows(updatedRows);
                                    }}
                                />

                                <TextField
                                    label="Manufacturing Date"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    margin="dense"
                                    type="date"
                                    value={row?.manufacturing_date}
                                    onChange={(e) => {
                                        const updatedRows = [...rows];
                                        updatedRows[index].manufacturing_date = e?.target?.value;
                                        setRows(updatedRows);
                                    }}
                                />

                                <TextField
                                    label="Expiry Date"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    margin="dense"
                                    type="date"
                                    value={row?.expiry_date}
                                    onChange={(e) => {
                                        const updatedRows = [...rows];
                                        updatedRows[index].expiry_date = e.target.value;
                                        setRows(updatedRows);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ))}

                {/* Add Row Button */}
                <IconButton onClick={handleAddRow} sx={{ marginTop: 2, alignSelf: 'flex-start' }} color="primary">
                    <AddIcon />
                </IconButton>
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button sx={{ color: '#00B251' }} onClick={() => setOpenAddDialog(false)}>
                    Cancel
                </Button>
                <Button sx={{ color: '#00B251' }} onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddInventory;
