import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {GetSingleFarmerDetailsbyFPO} from '../../Api_url'

const MemberProfile = () => {
    const { id } = useParams(); 

    // State to store various data
    const [member, setMember] = useState(null); // Farmer data
    const [landRecords, setLandRecords] = useState([]); // Land records
    const [totalLands, setTotalLands] = useState(0); // Total lands
    const [totalPosts, setTotalPosts] = useState(0); // Total posts
    const [farmerPosts, setFarmerPosts] = useState([]); // Farmer posts
    const [diseaseRecords, setDiseaseRecords] = useState([]); // Disease records
    const [totalDisease, setTotalDisease] = useState(0); // Total diseases
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state


    // Replace with your actual token
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        // Make the API request when the component mounts
        const fetchMemberData = async () => {
            try {
                const response = await axios.get(GetSingleFarmerDetailsbyFPO, {
                    headers: {
                        Authorization: `Bearer ${token}`, // Include token in the request header
                    },
                    params: {
                        farmer_id: id, // Send the farmer ID from URL in the request
                    },
                });

                // Assuming the response contains the member data
                const data = response?.data;

                setMember(data?.farmer_data);
                setLandRecords(data?.land_records);
                setTotalLands(data?.total_lands);
                setFarmerPosts(data?.farmer_posts);
                setTotalPosts(data?.total_posts);
                setDiseaseRecords(data?.disease_records);
                setTotalDisease(data?.total_disease);
            } catch (err) {
                setError('Error fetching member data');
                console.error(err);
            } finally {
                setLoading(false); // Set loading to false once the request is done
            }
        };

        fetchMemberData(); // Call the fetch function
    }, [id, token]); // Re-run the effect if `id` or `token` changes

    // Show loading or error state if needed
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    // If member data is fetched successfully, render it
    return (
        <div className="md:mt-10 mt-10 md:px-0 px-5">
        <div className="max-w-md mx-auto pb-6 bg-gradient-to-br from-[#00B251] to-[#66BB6A] shadow-2xl rounded-3xl overflow-hidden transform hover:scale-105 transition-all duration-500">
            {/* Profile Image */}
            <div className="flex justify-center mt-8">
                <img
                    className="w-32 h-32 rounded-full border-4 border-white shadow-xl object-cover transform hover:scale-110 transition-transform duration-300"
                    src={'https://apis.agrisarathi.com/' + (member?.profile || 'https://via.placeholder.com/150')}
                    alt={member?.name || 'Member'}
                />
            </div>
    
            {/* Profile Info */}
            <div className="text-center mt-6 px-6">
                <h2 className="text-2xl font-bold text-white">Name: {member?.name || 'USER NAME'}</h2>
                <p className="text-lg text-white mt-2">Mobile: {member?.mobile || '123-456-7890'}</p>
                <p className="text-lg text-white mt-1">Gender: {member?.gender || 'Unknown'}</p>
                <p className="text-lg text-white mt-1">Village: {member?.village || 'Unknown Village'}</p>
            </div>
    
            {/* Statistics Section */}
            <div className="flex justify-around mt-6 px-6 pb-6">
                {/* Total Lands */}
                <div className="flex flex-col items-center bg-blue-500 py-4 px-6 rounded-xl shadow-lg">
                    <h3 className="text-2xl font-bold text-white">{totalLands || 0}</h3>
                    <p className="text-sm text-white">Total Lands</p>
                </div>
                {/* Total Posts */}
                <div className="flex flex-col items-center bg-purple-500 py-4 px-6 rounded-xl shadow-lg">
                    <h3 className="text-2xl font-bold text-white">{totalPosts || 0}</h3>
                    <p className="text-sm text-white">Total Posts</p>
                </div>
                {/* Total Diseases */}
                <div className="flex flex-col items-center bg-orange-500 py-4 px-6 rounded-xl shadow-lg">
                    <h3 className="text-2xl font-bold text-white">{totalDisease || 0}</h3>
                    <p className="text-sm text-white">Total Diseases</p>
                </div>
            </div>
    
            {/* Decorative Accent Line */}
            <div className="h-1 bg-gradient-to-r from-[#19ce6a] to-[#A0E0B4] mt-6 mx-8 rounded-full"></div>
        </div>
    </div>
    
    );
};

export default MemberProfile;
